
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditSupplierIntraModal from "@/components/modals/referencials/suppliers-intra/EditSupplierIntraModal.vue";
import SearchSupplierIntraModal from "@/components/modals/referencials/suppliers-intra/SearchSupplierIntraModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-suppliers-intra",
    props: {
        widgetClasses: String,
    },
    components: {
        EditSupplierIntraModal,
        SearchSupplierIntraModal,
    },
    setup() {
        const { t, te } = useI18n();
        let loading = ref(false);
        let activeSupplierIntra = ref(null);
        const suppliersIntra = ref([]);
        const queryParamsDefault = {
            name   : "",
            phone  : "",
            address: "",
            note   : "",
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            name   : translate("supplierIntraNameLabel"),
            phone  : translate("supplierIntraPhoneLabel"),
            address: translate("supplierIntraAddressLabel"),
            note   : translate("supplierIntraNoteLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getSuppliersIntra();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getSuppliersIntra();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getSuppliersIntra();
        };

        const create = () => {
            activeSupplierIntra.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_supplier_intra'));
            modal.show();
        };

        const edit = (supplier) => {
            activeSupplierIntra.value = supplier;
            const modal = new Modal(document.getElementById('kt_modal_edit_supplier_intra'));
            modal.show();
        };

        const confirmRemove = (supplier_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("supplierIntraConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(supplier_id);
                }
            })
        }

        const remove = (supplierIntra_id: number) => {

            axios.delete(`${ApiRoutes.suppliersIntra.delete}/${supplierIntra_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getSuppliersIntra = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.suppliersIntra.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    suppliersIntra.value = response.data.rows;
                }).catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchSuppliersIntra = (params) => {
            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getSuppliersIntra();

            setCurrentPageBreadcrumbs(translate("supplierIntraManagementHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("externalCompaniesManagementHeading")
            ]);
        });

        return {
            translate,
            suppliersIntra,
            queryParams,
            queryParamsLabels,
            queryParamsDefault,
            removeQueryParam,
            searchSuppliersIntra,
            pagination,
            activeSupplierIntra,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
