
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "edit-supplier-intra-modal",
    emit: ["refreshData"],
    props: {
        supplierIntra: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let isUpdate = false as boolean;
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editSupplierIntraModalRef = ref<null | HTMLElement>(null);
        const supplierIntraDefault = {
            id: null,
            phone: "",
            name: "",
            address: "",
            note: "",
        };
        const supplierIntraData = ref<any>({});
        Object.assign(supplierIntraData.value, supplierIntraData);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            name: Yup.string().required().label(translate("supplierIntraNameLabel")),
            phone: Yup.string().required().label(translate("supplierIntraPhoneLabel")),
            address: Yup.string().nullable().label(translate("supplierIntraAddressLabel")),
            note: Yup.string().required().label(translate("supplierIntraNoteLabel")),
        });

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.supplierIntra.id) supplierIntraData.value.id = props.supplierIntra.id;

            axios.post(ApiRoutes.suppliersIntra.updateOrCreate, supplierIntraData.value)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editSupplierIntraModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            const modal = document.getElementById('kt_modal_edit_supplier_intra');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(supplierIntraData.value, supplierIntraDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;

                if (props.supplierIntra) {
                    isUpdate = true;

                    // Fill Intra fields
                    Object.keys(props.supplierIntra).forEach((key) => {
                        if (supplierIntraDefault.hasOwnProperty(key)) supplierIntraData.value[key] = props.supplierIntra[key];
                    });
                }
            });
        });

        return {
            translate,
            supplierIntraData,
            validationSchema,
            submit,
            submitButtonRef,
            editSupplierIntraModalRef,
        };
    },
});
